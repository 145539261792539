<!-- Temp -->
<div *ngIf="!show">
    <label>Enter password</label>
    <input [style.fontSize]="'17px'" [formControl]="password" name="password" type="password">
    <button (click)="login()">Login</button>
</div>
<!-- TEMP END -->

<app-header *ngIf="show"></app-header>
<ng-container *ngIf="show && !isLoading">
    <router-outlet></router-outlet>
</ng-container>