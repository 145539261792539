import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) },
  { path: 'list', loadChildren: () => import('./pages/list/list.module').then(m => m.ListModule) },
  { path: 'impressum', loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumModule) },
  { path: 'dank', loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksModule) },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: '**', loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
