import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IUnit } from 'src/app/models/unit.model';
import { DeviceService } from 'src/app/services/device.service';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public hoverUnit: IUnit | undefined = undefined;
  public defaultCity = 'Zürich';
  public currentCity = this.defaultCity;
  public isListView = false;
  public isSubPage = true;
  public isOpen = true;
  public isHovered = false;
  public isIntro = true;

  private hoverUnitSub: Subscription | null = null;
  private isIntroSub: Subscription | null = null;

  constructor(
    private readonly router: Router,
    private readonly unitService: UnitService,
    private readonly deviceService: DeviceService
  ) { }

  ngOnInit(): void {
    this.hoverUnitSub = this.unitService.$currentUnit.subscribe(
      ($unit: IUnit | undefined) => {
        this.hoverUnit = $unit;

        if ($unit) {
          this.currentCity = $unit.city;
        } else {
          this.currentCity = this.defaultCity;
        }
      }
    );

    this.isIntroSub = this.unitService.$isIntro.subscribe(
      (isIntro: boolean) => {
        this.isIntro = isIntro;

        of(void 0).pipe(delay(1700)).subscribe(() => {
          if (!this.isHovered) {
            this.isOpen = false;
          }
        });
      }
    );
  }

  ngAfterViewInit(): void {
    of(void 0).pipe(delay(300)).subscribe(() => {
      this.updateHeader();
    });

    of(void 0).pipe(delay(1000)).subscribe(() => {
      if (!this.isIntro) {
        if (!this.isHovered) {
          this.isOpen = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.hoverUnitSub) {
      this.hoverUnitSub.unsubscribe();
    }
    if (this.isIntroSub) {
      this.isIntroSub.unsubscribe();
    }
  }

  private updateHeader(): void {
    const route = this.router.url.split('?')[0];
    this.isSubPage = true;
    this.isIntro = false;

    if (route === '/list') {
      this.isSubPage = false;
      this.isListView = true;
    }
    if (route === '/' || route === '') {
      this.isSubPage = false;
      this.isListView = false;
      this.isIntro = true;
    }
  }

  public toggleView(): void {
    const route = this.router.url.split('?')[0];
    // this.radioService.resetPlayer();

    if (route === '/list') {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['list']);
    }
  }

  public onMouseEnter(): void {
    if (!this.deviceService.isMobile() && !this.deviceService.isTablet()) {
      this.openHeader();
    }
  }

  public onMouseLeave(): void {
    if (!this.deviceService.isMobile() && !this.deviceService.isTablet()) {
      this.closeHeader();
    }
  }

  public openHeader(): void {
    this.isHovered = true;
    this.isOpen = true;
  }

  public closeHeader(): void {
    this.isHovered = false;
    this.isOpen = false;
  }
}
