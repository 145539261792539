<div class="aa-page-switch" *ngIf="!isSubPage && !isIntro">
    <mat-slide-toggle (click)="toggleView()" [(ngModel)]="isListView"></mat-slide-toggle>
    <img class="aa-switch-background" src="../../../assets/icons/Atelieratlas_Icons_Switch-Frame.svg" />
</div>
<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"
    [ngClass]="{'open' : isOpen, 'aa-header-inner': true, 'isIntro': isIntro}">
    <div class="aa-header-topbar">
        <div class="aa-header-topbar-inner">
            <a [routerLink]="['/']">
                <h3>Atelieratlas<span *ngIf="currentCity" class="aa-special-character">-</span>{{currentCity}}</h3>
            </a>
            <img *ngIf="!isOpen" class="aa-header-burger" src="../../../assets/icons/Atelieratlas_Icons_Menu-Burger.svg"
                (click)="openHeader()" />
            <img *ngIf="isOpen" class="aa-header-burger"
                src="../../../assets/icons/Atelieratlas_Icons_Close-X_black.svg" (click)="closeHeader()" />
        </div>
        <app-player></app-player>
    </div>
    <div class="aa-header-bottombar">
        <div class="aa-header-left">
            <a [routerLink]="['/about']">
                <p>About</p>
            </a>
            <a [routerLink]="['/dank']">
                <p>Dank</p>
            </a>
            <a href="https://www.spotify.com/" target="_blank">
                <img src="../../../assets/icons/Atelieratlas_Icons_Spotify.svg" alt="Spotify Link" />
            </a>
            <a href="https://soundcloud.com/" target="_blank">
                <img src="../../../assets/icons/Atelieratlas_Icons_Soundcloud.svg" alt="Soundcloud Link" />
            </a>
            <a href="https://www.apple.com/apple-music/" target="_blank">
                <img src="../../../assets/icons/Atelieratlas_Icons_Apple-Music.svg" alt="Apple Music Link" />
            </a>
        </div>
        <div class="aa-header-right">
            <a [routerLink]="['/impressum']">
                <p>Impressum</p>
            </a>
        </div>
    </div>
</div>