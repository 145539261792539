import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IUnit } from './models/unit.model';
import { UnitService } from './services/unit.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public title = 'atelieratlas';
  public isLoading = true;
  public show = false;

  public password = new FormControl('', [Validators.required]);

  constructor(
    private renderer: Renderer2,
    private readonly unitService: UnitService,
    private readonly router: Router,
  ) {
    this.unitService.getUnits().subscribe((units: IUnit[]) => {
      if (units) {
        this.isLoading = false;
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setSubPage();
      }
    });
  }

  ngAfterViewInit(): void {
    of(void 0).pipe(delay(10)).subscribe(() => {
      this.setSubPage();
    });
  }

  public login(): void {
    if (this.password.value === 'N28zrQ^W!i7CU^i') {
      this.show = true;
    }
  }

  private setSubPage(): void {
    const route = this.router.url.split('?')[0];
    if (route !== '/list' && route !== '/' && route !== '') {
      this.renderer.addClass(document.body, 'sub-page');
    } else {
      this.renderer.removeClass(document.body, 'sub-page');
    }

    if (route === '/' || route === '') {
      this.renderer.addClass(document.body, 'map');
    } else {
      this.renderer.removeClass(document.body, 'map');
    }
  }
}
