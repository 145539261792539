<div class="aa-radio-player" *ngIf="currentUnit && currentPodcast" (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">
    <p>{{currentUnit.headline}}</p>
    <ngx-audio-player #audioPlayer [playlist]="playlist" [displayTitle]="false" [autoPlay]="true" muted="muted"
        [displayPlaylist]="false" [displayVolumeControls]="false" [displayRepeatControls]="false"
        [disablePositionSlider]="false" [displayArtist]="false" [displayDuration]="true" [expanded]="false"
        (trackEnded)="onEnded($event)">
    </ngx-audio-player>
    <img class="aa-play-btn" *ngIf="!currentUnit.isPlaying" src="../../../assets/icons/Atelieratlas_Icons_Play.svg"
        alt="Player play button" (click)="play()" />
    <img class="aa-pause-btn" *ngIf="currentUnit.isPlaying" src="../../../assets/icons/Atelieratlas_Icons_Pause.svg"
        alt="Player pause button" (click)="pause()" />
</div>
