import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import {
  Observable,
  throwError,
} from 'rxjs';
import {
  map,
  catchError
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'c31z'
    })
  };

  constructor(private http: HttpClient) { }

  /**
   * Function to GET what you want
   *
   * @param url for the requested ressource
   */
  public get(url: string): Observable<any> {
    return this.http.get(url, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Function to handle error when the server return an error
   *
   * @param error to handle
   */
  private handleError(error: HttpErrorResponse): Observable<void> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }
}
