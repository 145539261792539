import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUnit } from '../models/unit.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  public units: IUnit[] = [];
  public $currentUnit = new Subject<IUnit>();
  public $isIntro = new Subject<boolean>();
  public isInitiated = false;

  constructor(private readonly api: ApiService) { }

  public getUnits(): Observable<IUnit[]> {
    return this.api.get('/api/interviews').pipe(
      map(($units: IUnit[]) => {
        // console.log($units);
        this.units = $units;
        return this.units;
      }));
  }

  public updateCurrentUnit(unit: IUnit | undefined): void {
    this.$currentUnit.next(unit);
  }

  public updateIntroState(isIntro: boolean): void {
    if (!this.isInitiated) {
      this.$isIntro.next(isIntro);
    }
  }
}
