import { OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Track } from 'ngx-audio-player';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IData, IUnit } from 'src/app/models/unit.model';
import { DeviceService } from 'src/app/services/device.service';
import { RadioService } from 'src/app/services/radio.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy {
  @ViewChild('audioPlayer', { static: false }) audioPlayer: any | null = null;

  public currentUnit: IUnit | null = null;
  public currentPodcast: IData | null = null;
  public playlist: Track[] = [];

  private playRadioSub: Subscription | null = null;
  private pauseRadioSub: Subscription | null = null;
  private resetPlayerSub: Subscription | null = null;

  constructor(
    protected radioService: RadioService,
    protected deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.playRadioSub = this.radioService.$playRadio.subscribe(
      ($unit: IUnit) => {
        if (this.currentUnit) {
          this.currentUnit.isPlaying = false;
        }

        this.currentUnit = $unit;
        this.currentPodcast = this.getPodcast($unit);

        this.initAudio();
      }
    );

    this.pauseRadioSub = this.radioService.$pauseRadio.subscribe(() => {
      this.pause();
    });

    this.resetPlayerSub = this.radioService.$resetPlayer.subscribe(() => {
      // this.resetPlayer();
    });
  }

  ngOnDestroy(): void {
    if (this.playRadioSub) {
      this.playRadioSub.unsubscribe();
    }
    if (this.pauseRadioSub) {
      this.pauseRadioSub.unsubscribe();
    }
    if (this.resetPlayerSub) {
      this.resetPlayerSub.unsubscribe();
    }
  }

  public initAudio(): void {
    if (this.currentUnit && this.currentPodcast) {
      this.currentUnit.isPlaying = true;

      of(void 0)
        .pipe(delay(150))
        .subscribe(() => {
          if (this.audioPlayer && this.currentUnit && this.currentPodcast) {
            this.playlist = [
              {
                title: this.currentUnit.headline,
                link: this.currentPodcast.url,
              },
            ];

            this.audioPlayer.play();
          }
        });
    }
  }

  public play(): void {
    if (this.audioPlayer && this.currentUnit) {
      this.currentUnit.isPlaying = true;
      this.audioPlayer.player.nativeElement.play();
    }
  }

  public pause(): void {
    if (this.audioPlayer && this.currentUnit) {
      this.currentUnit.isPlaying = false;
      this.audioPlayer.player.nativeElement.pause();
    }
  }

  public onEnded(e: string): void {
    of(void 0)
      .pipe(delay(150))
      .subscribe(() => {
        this.pause();
      });
  }

  public onTouchStart(e: Event): void {
    this.deviceService.setGlobalDragEnabled(false);
  }

  public onTouchEnd(e: Event): void {
    this.deviceService.setGlobalDragEnabled(true);
  }

  public resetPlayer(): void {
    this.pause();
    this.currentUnit = null;
    this.currentPodcast = null;
  }

  private getPodcast($unit: IUnit): IData | null {
    for (const key in $unit.podcast) {
      if ($unit.podcast[key]) {
        return $unit.podcast[key];
      }
    }

    return null;
  }
}
