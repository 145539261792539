import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IUnit } from '../models/unit.model';

@Injectable({
  providedIn: 'root'
})
export class RadioService {
  public $playRadio = new Subject<IUnit>();
  public $pauseRadio = new Subject<void>();
  public $resetPlayer = new Subject<void>();

  constructor() { }

  public playRadio($unit: IUnit): void {
    this.$playRadio.next($unit);
  }

  public pauseRadio(): void {
    this.$pauseRadio.next();
  }

  public resetPlayer(): void {
    this.$resetPlayer.next();
  }
}
